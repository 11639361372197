'use strict';
$(
	function() {
		try {
			var $midLine = $('.top .middle-line');
			app.fixTop = $midLine.length ? $midLine.offset().top + $midLine.outerHeight() + $('._ > .top .udu__u-menu').height() : 0;
		} catch (e) {}
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.attachSvgEvents();
		app.initACBL();
		app.setBackUrls();
		app.addCoverSpan();
		app.hamburger.init({
			look: 1
		});
		app.topMenu.init();
		if(!app.isMobile()) {
			$( window ).scroll(
				function(){
					app.floatingHeader();
				}
			);
			app.floatingHeader();
		}
		app.assignProductOver();
		app.initializeScrollButton();
		app.extraMenu && app.extraMenu.init({side: 'left'});
		app.template.set();
	}
);

var app = {
	_VERSION: 2,
	_vars: {
		v: 1.4,
		tmp: 21,
		putAttributesToProdItemsDirectly: true,
		_guid: "9ae7d829-4fbc-4849-92f9-930a1a6c680a",
		_customFonts: {
			Linearicons: true
		}
	},
	responsiveMode: true,
	indexLinkPos: 0,
	messages: {},
	productAvailableQuantityUrl: null,
	optionsWereInitialized: false,
	waitBlockZIndex: 9999,
	showSearch : function() {
		if ($('.li-search .ul-mega-1').hasClass('hidden')) {
			$('.li-search').addClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
		} else {
			$('.li-search').removeClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
		}
	},
	runIndexCarusel: function() {
		app.runCarusel({
			innerHTML: '<div class="zoomer"><div class="inner"></div></div>'
		});
		$('.jcarousel-list li').each(
			function() {
				var href = $(this).find('.image a').attr('href');
				$(this).find('.zoomer').click(
					function() {
						document.location = href;
					}
				)
			}
		)
	},
	doBeforePriceSliderShown: function(trg) {
		var color = app.getClassColor('schema-color');
		$('.noUi-connect').css('background-color', color);
	},
	template: {
		set: function () {
			$('.menu > ul > li').addClass('schema-brd-color-over');
		}
	}
};